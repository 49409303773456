import React from "react";

const MintHeader = () => {
    return(
        <div style={{backgroundColor:"#050023"}}>
            <div className="container">
                <div style={{display:'flex', gap:'50px'}}>
    
                    <h3 className="btnlog btn ml-lg-2 btn-bordered-white" style={{ color: 'lightyellow', fontStyle: 'bold', textAlign: 'left', display:'flex',cursor:'pointer', textTransform: 'uppercase', margin:'0px 0px 0px 30px' }}>
                        Go to Staking Site
                        <i className="fas fa-arrow-left" style={{marginRight:'20px'}}></i>
                    </h3>
 
                </div>
             </div>
        </div>
    )
}

export default MintHeader;