import React, { useEffect, useState } from 'react'
import nft from '../../abi/nft.json'
import {
    nftMintingContract1,
    nftMintingContract2,
    nftStakingContract1,
    nftStakingContract2,
    adminWallet,
} from '../../config';
import { Link, NavLink } from 'react-router-dom';
import { Modal, Spinner, ProgressBar } from 'react-bootstrap'
import { IoIosAddCircle } from "react-icons/io";
// import { useMoralis, useMoralisQuery } from "react-moralis";

import 'reactjs-popup/dist/index.css';
function Csdoge({ acc, web3main }) {

    const [allfixed, setallfix] = useState([])
    const [allfixed1, setallfix1] = useState([])
    const [allp, setallp] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [cklist, setcklist] = useState([])
    const [accountid, setaccountid] = useState()
    const [burnc, setburnc] = useState()

    useEffect(async () => {
        if (acc && web3main) {
            nftidnew();
            nftidnew1()
            const accounts1 = await web3main.eth.getAccounts();
            setaccountid(accounts1[0])
        }
    }, [acc, web3main])

    const nftidnew = async () => {
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            console.log('ccc', userwalletaddresss)
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)
            swaping.methods.csdogenft().call({ from: userwalletaddresss })
                .then((id) => {
                    console.log("id", id);
                    var listlen = id?.length
                    for (let i = 0; i < listlen; i++) {
                        console.log(id[i])
                        nftinfo(id[i])
                        salenftprie(id[i])
                        tokeninfo(id[i])
                    }
                })
                .catch()
        }
    }
    const nftinfo = async (id) => {
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)

            swaping.methods.nftInformation(id).call({ from: userwalletaddresss })
                .then((fees) => {
                    swaping.methods.listofsalenft(id).call({ from: userwalletaddresss })
                        .then((length) => {
                            swaping.methods.csdogeinfo(id).call({ from: userwalletaddresss })
                                .then((feesone) => {
                                    savelist({ ...fees, 10: feesone[0] ? Number(length[3]) / 1000000000 : Number(length[3]) / 1000000000000000000, 11: feesone[0], 12: Number(feesone[1]), 13: Number(feesone[2]) })

                                })
                                .catch()
                        })
                        .catch()
                }).catch()

        }
    }
    const savelist = (data) => {
        setallfix((old) => [
            ...old, data
        ])
    }

    const nftidnew1 = async () => {

        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            console.log('ccc', userwalletaddresss)
            let swaping = new web3main.eth.Contract(nft, nftMintingContract2)

            swaping.methods.csdogenft().call({ from: userwalletaddresss })
                .then((id) => {
                    console.log("id", id);
                    var listlen = id?.length
                    // console.log('bn',length[0])
                    for (let i = 0; i < listlen; i++) {
                        // console.log(`akk${i}`,length[0][i])
                        console.log(id[i])
                        nftinfo1(id[i])
                        salenftprie(id[i])
                        tokeninfo1(id[i])


                    }
                })
                .catch()
        }
    }
    const nftinfo1 = async (id) => {

        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            console.log('ccc', userwalletaddresss)
            let swaping = new web3main.eth.Contract(nft, nftMintingContract2)

            swaping.methods.nftInformation(id).call({ from: userwalletaddresss })
                .then((fees) => {
                    swaping.methods.listofsalenft(id).call({ from: userwalletaddresss })
                        .then((length) => {
                            swaping.methods.csdogeinfo(id).call({ from: userwalletaddresss })
                                .then((feesone) => {
                                    savelist1({ ...fees, 10: feesone[0] ? Number(length[3]) / 1000000000 : Number(length[3]) / 1000000000000000000, 11: feesone[0], 12: Number(feesone[1]), 13: Number(feesone[2]) })
                                })
                                .catch()
                        })
                        .catch()
                }).catch()
        }
    }
    const savelist1 = (data) => {
        setallfix1((old) => [
            ...old, data
        ])
    }
    // const dataa = useMoralisQuery('CREATECSDOGENFT')

    // const fildata = dataa?.data?.filter((v) => Number(v?.attributes?.csprice) > 0)
    const salenftprie = async (id) => {
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)
            swaping.methods.listofsalenft(id).call({ from: userwalletaddresss })
                .then((length) => {
                    const val = {
                        id: id, value: Number(length[3]) / 1000000000000000000
                    }
                    getallprice(val)
                })
                .catch()
        }
    }
    const getallprice = (data) => {
        if (data.id === "0") {
        } else {
            setallp((old) => [
                ...old, data
            ])
        }
    }
    const tokeninfo = async (tokenid) => {
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            console.log('ccc', userwalletaddresss)
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)
            swaping.methods.csdogeinfo(tokenid).call({ from: userwalletaddresss })
                .then((fees) => {
                    const val = {
                        id: tokenid,
                        ck: fees[0],
                        copy: Number(fees[1]),
                        total: Number(fees[2])
                    }
                    checklist(val)
                })
                .catch()
        }
    }
    const tokeninfo1 = async (tokenid) => {
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            console.log('ccc', userwalletaddresss)
            let swaping = new web3main.eth.Contract(nft, nftMintingContract2)
            swaping.methods.csdogeinfo(tokenid).call({ from: userwalletaddresss })
                .then((fees) => {
                    const val = {
                        id: tokenid,
                        ck: fees[0],
                        copy: Number(fees[1]),
                        total: Number(fees[2])
                    }
                    checklist(val)


                })
                .catch()

        }
    }
    const checklist = (data) => {
        setcklist((old) => [
            ...old, data
        ])

    }
    const burncopiesnft = async (tokenid, copiesnumber) => {
        setShow(true)
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            console.log('ccc', userwalletaddresss)
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)

            swaping.methods.burncopies(copiesnumber, tokenid).send({ from: userwalletaddresss })
                .then((fees) => {
                    console.log(fees);
                    setShow(false)
                    window.location.reload()
                })
                .catch(() => {
                    setShow(false)
                })

        }
    }
    return (
        <section className="popular-collections-area csdogemain">
            <div style={{backgroundColor:"#050023"}}>
                <div className="container">
                    <div style={{display:'flex', gap:'50px', justifyContent:"flex-end"}}>
        
                        <Link to="/staking" className="btnlog btn ml-lg-2 btn-bordered-white" style={{ color: 'lightyellow', fontStyle: 'bold', textAlign: 'left', display:'flex',cursor:'pointer', textTransform: 'uppercase', margin:'0px 0px 0px 30px' }}>
                            Go to Staking Site
                            <i className="fas fa-arrow-right" style={{marginLeft:'20px'}}></i>
                        </Link>
    
                    </div>
                </div>
            </div>
            <div className="container-fuild px-3 py-3 mt-5">
                <div className="row">
                    <div className="col-12">
                        <div className="intro d-flex align-items-end m-0">
                            <div className="intro-content d-flex justify-content-between">
                                <span className='p-2 btn btn-bordered-white btn-smaller p-3'>CS DOGE SILVER NFT</span>
                                <h3 className="mt-3 mb-0">CS DOGE NFT</h3>
                            </div>
                            <div className='card px-3 ml-5'>
                                <span className='p-2 p-3'>Infos: STAKING NFT CARDS "silver Pool"</span>
                                <h3>STAKING NFT CARDS</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row items pl-3 ml-3">
                    {
                        accountid?.toLowerCase() === adminWallet?.toLowerCase() ?
                            <div className="col-12 csmain col-sm-6 col-lg-3 item">
                                <div className="card no-hover text-center" style={{ height: '470px' }}>

                                    {/* Card Caption */}
                                    <div className="card-body">
                                        <NavLink to={`/createcsdoge/${nftMintingContract1}`}>
                                            <IoIosAddCircle className="csdogeadd" style={{ fontSize: '250px', color: '#99B7FF' }} />
                                        </NavLink>
                                        <div className="card-bottom mt-3 text-center">
                                            <h3 style={{ fontSize: '28px' }} className="m-0">CREATE</h3>
                                            <h3 style={{ fontSize: '28px' }}>CSDOGE</h3>
                                        </div>
                                        <div >
                                        </div>
                                    </div>
                                </div>
                            </div> : null
                    }
                    {
                        accountid && acc ?


                            <>
                                {allfixed?.map((val, id) => {
                                    return (
                                        <div key={`cd_${id}`} className="col-12 csmain col-sm-6 col-lg-3 item">
                                            <div className="card no-hover text-center">
                                                <div className="image-over csimg">
                                                    <Link to={`/cs-details/${val[0]}/${nftMintingContract1}/${nftStakingContract1}`} >
                                                        <img className="card-img-top" src={`https://loopart.mypinata.cloud/ipfs/${val ? val[6] : null}`} alt="" />
                                                    </Link>
                                                </div>
                                                {/* Card Caption */}
                                                <div className="card-caption col-12 p-0 csdetail">

                                                    <Link to={`/cs-details/${val[0]}`}>

                                                        <h5 style={{ textTransform: 'capitalize' }} className="mb-0">{val ? val[1] : null}</h5>

                                                    </Link>

                                                    <div className="card-body">

                                                        <form onSubmit={(e) => {
                                                            e.preventDefault()
                                                            burncopiesnft(val[0], burnc)
                                                        }}>
                                                            {
                                                                accountid === adminWallet ? <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <input type="number" min="0" style={{ border: 'none', outline: 'none' }} placeholder="Burn Copies Number" onChange={(e) => setburnc(e.target.value)} required />
                                                                    <button type="submit" className="btn btn-bordered-white btn-smaller mt-3">Burn</button>
                                                                </span> : null
                                                            }

                                                        </form>
                                                        <div className="seller d-flex align-items-center">
                                                            <span >Owned By</span>
                                                            <Link to={`/cs-details/${val[0]}`}>
                                                                <h6 style={{ textTransform: 'capitalize' }} className="ml-2 mb-0">{val ? val[3] : null}</h6>
                                                            </Link>

                                                        </div>
                                                        <div className="card-bottom d-flex justify-content-between">
                                                            <span>{val ? val[10] : null} {val ? val[11] ? "CSDOGE" : "BNB" : null}</span>
                                                            {
                                                                <span >{val ? val[12] : null} of {val ? val[13] : null} </span>
                                                            }
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <ProgressBar className="mx-3" now={100 - (val ? val[12] : null / val ? val[13] : null) * 100} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </> :
                            <>
                            </>}
                </div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}><Spinner animation="grow" variant="light" />
                            <Spinner animation="grow" variant="light" />
                            <Spinner animation="grow" variant="light" />
                            <Spinner animation="grow" variant="light" />
                            <Spinner animation="grow" variant="light" />
                            <Spinner animation="grow" variant="light" />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <div className="container-fuild px-3 py-3 mt-5">
                <div className="row">
                    <div className="col-12">
                        {/* Intro */}
                        <div className="intro d-flex align-items-end m-0">
                            <div className="intro-content">
                                <span className='p-2 btn btn-bordered-white btn-smaller p-3'>CS DOGE Golden nft</span>
                                <h3 className="mt-3 mb-0">CS DOGE Golden nft</h3>
                            </div>
                            <div className='card px-3 ml-5'>
                                <span className='p-2 p-3'>Infos: staking nft card "golden pool"</span>
                                <h3>Infos: Independence Day</h3>
                            </div>
                            {/* <div className="intro-btn">
                                    <a className="btn content-btn text-left" href="/explore-2">{this.state.data.btnText}</a>
                                </div> */}
                        </div>
                    </div>
                </div>
                <div className="row items">
                    {
                        accountid?.toLowerCase() === adminWallet?.toLowerCase() ?
                            <div className="col-12 csmain col-sm-6 col-lg-3 item">
                                <div className="card no-hover text-center" style={{ height: '470px' }}>
                                    {/* Card Caption */}
                                    <div className="card-body">
                                        <NavLink to={`/createcsdoge/${nftMintingContract2}`}>
                                            <IoIosAddCircle className="csdogeadd" style={{ fontSize: '250px', color: '#99B7FF' }} />
                                        </NavLink>
                                        <div className="card-bottom mt-3 text-center">
                                            <h3 style={{ fontSize: '28px' }} className="m-0">CREATE</h3>
                                            <h3 style={{ fontSize: '28px' }}>CSDOGE</h3>
                                        </div>
                                    </div>
                                </div>
                            </div> : null
                    }
                    {
                        accountid && acc ?
                            <>
                                {allfixed1?.map((val, id) => {
                                    return (
                                        <div key={`cd_${id}`} className="col-12 csmain col-sm-6 col-lg-3 item">
                                            <div className="card no-hover text-center">
                                                <div className="image-over csimg">
                                                    <Link to={`/cs-details/${val[0]}/${nftMintingContract2}/${nftStakingContract2}`} >
                                                        <img className="card-img-top" src={`https://loopart.mypinata.cloud/ipfs/${val ? val[6] : null}`} alt="" />
                                                    </Link>
                                                </div>
                                                {/* Card Caption */}
                                                <div className="card-caption col-12 p-0 csdetail">

                                                    <Link to={`/cs-details/${val[0]}`}>

                                                        <h5 style={{ textTransform: 'capitalize' }} className="mb-0">{val ? val[1] : null}</h5>

                                                    </Link>

                                                    <div className="card-body">

                                                        <form onSubmit={(e) => {
                                                            e.preventDefault()
                                                            burncopiesnft(val[0], burnc)
                                                        }}>
                                                            {
                                                                accountid === adminWallet ? <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <input type="number" min="0" style={{ border: 'none', outline: 'none' }} placeholder="Burn Copies Number" onChange={(e) => setburnc(e.target.value)} required />
                                                                    <button type="submit" className="btn btn-bordered-white btn-smaller mt-3">Burn</button>
                                                                </span> : null
                                                            }

                                                        </form>
                                                        <div className="seller d-flex align-items-center">
                                                            <span >Owned By</span>
                                                            <Link to={`/cs-details/${val[0]}`}>
                                                                <h6 style={{ textTransform: 'capitalize' }} className="ml-2 mb-0">{val ? val[3] : null}</h6>
                                                            </Link>

                                                        </div>
                                                        <div className="card-bottom d-flex justify-content-between">
                                                            {
                                                                <span >{val ? val[12] : null} of {val ? val[13] : null} </span>
                                                            }
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <ProgressBar className="mx-3" now={100 - (val ? val[12] : null / val ? val[13] : null) * 100} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </> :
                            <>
                               
                            </>}
                </div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}><Spinner animation="grow" variant="light" />
                            <Spinner animation="grow" variant="light" />
                            <Spinner animation="grow" variant="light" />
                            <Spinner animation="grow" variant="light" />
                            <Spinner animation="grow" variant="light" />
                            <Spinner animation="grow" variant="light" />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </section>
    );
}


export default Csdoge;