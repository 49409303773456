import React, { Component } from 'react';
import Creates from '../components/Create/Createcsdoge';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';

function Create ({acc, web3main,prov }) {
 
        return (
            <div className="main mt-5">
                {/* <Header /> */}
                {/* <Breadcrumb title="Create" subpage="Pages" page="Create" /> */}
                <Creates acc={acc}   web3main={web3main} prov={prov}/>
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }


export default Create;