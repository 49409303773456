import React from 'react'
import MyExplore from '../components/Explore/Csdoge';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
function Csdogemain({acc ,  web3main, prov}) {
    return (
        <div className="main">
                <MyExplore acc={acc}  web3main={web3main} prov={prov}/>
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
        </div>
    )
}

export default Csdogemain
