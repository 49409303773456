import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Web3 from 'web3'
import nft from '../../abi/abi.json'
import { Link, withRouter } from 'react-router-dom';
import stack from '../../abi/stack.json'

import queryString from 'query-string';
import { Modal, Spinner } from 'react-bootstrap'

function Staking({ acc, web3main, ...props }) {
    // ////////console.log("address", addrs)
    const location = useLocation()
    // const fdata = location.state
    const { id } = useParams()
    ////////console.log(id)
    const { nftMintingContract1, nftStakingContract1 } = queryString.parse(props.location.search)
    //console.log(queryString.parse(props.location.search), '~~~~~~~~~~~~~~~~')
    useEffect(() => {
        //console.log(nftMintingContract1, nftStakingContract1, '~~~~~~~~~~~~~~~~~~~')
        if (acc && web3main && nftMintingContract1 && nftStakingContract1) {
            nftinfo(id)
            nftstackdetails()
        }

    }, [acc, web3main])

    const zeroAddress = "0x0000000000000000000000000000000000000000";
    const [show, setShow] = useState(false);
    const [fdata, setfdata] = useState()
    const handleClose = () => setShow(false);
    const [stcheck, setstcheck] = useState()
    const [account, setaccount] = useState()
    const [amountToStaking, setAmountTostaking] = useState();
    const [amountToUnstaking, setAmountToUnstaking] = useState();
    const [stakingError, setStakingError] = useState(false);
    const [unstakingError, setUnstakingError] = useState(false);

    const nftinfo = async () => {
        if (web3main && acc) {
            const accounts = await web3main.eth.getAccounts();
            setaccount(accounts[0])
            let userwalletaddresss = accounts[0];
            // web3main = new Web3(web3main && acc);
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)
            swaping.methods.nftInformation(id).call({ from: userwalletaddresss })
                .then((fees) => {
                    ////console.log("pop22", fees)
                    debugger
                    setfdata(fees)
                    if (fees[8] == true) {
                        swaping.methods.balanceOf(userwalletaddresss, id).call({ from: userwalletaddresss })
                            .then((info) => {
                                setfdata({ ...fees, 10: info });
                            })
                    }
                }).catch(err => {

                })


        }
    }

    const nftstack = async () => {
        debugger
        if (!amountToStaking) {
            setUnstakingError(false)
            setStakingError(true)
        }
        else {
            setShow(true)
            if (web3main && acc) {

                const accounts = await web3main.eth.getAccounts();
                let userwalletaddresss = accounts[0];
                let swaping = new web3main.eth.Contract(nft, nftMintingContract1)
    
                swaping.methods.nftstake(id).send({ from: userwalletaddresss })
                    .then((fees) => {
                        //////console.log(fees);
                        nftlock()
                    }).catch(err =>
                        console.log(err)
                    )

            }
        }
    }

    const nftlock = async () => {
        if (web3main && acc) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            // web3main = new Web3(web3main && acc);
            let swaping1 = new web3main.eth.Contract(stack, nftStakingContract1)

            swaping1.methods.stakenft(id, amountToStaking).send({ from: userwalletaddresss })
                .then((fees) => {
                    ////////console.log(fees);
                    setShow(false)
                    window.location.reload()
                }).catch()



        }
    }

    const nftlock11 = async () => {
        if (!amountToUnstaking) {
            setUnstakingError(true)
            setStakingError(false)
        }
        else {
            if (web3main && acc)
                setShow(true)
            {
                const accounts = await web3main.eth.getAccounts();
                //  ////////console.log(accounts);
                let userwalletaddresss = accounts[0];
                // web3main = new Web3(web3main && acc);
                let swaping = new web3main.eth.Contract(stack, nftStakingContract1)
                swaping.methods.unstakenft(id, amountToUnstaking).send({ from: userwalletaddresss, value: 0 })
                    .then((fees) => {
                        ////////console.log(fees);
                        setShow(false)
                        window.location.reload()
                    }).catch()

            }
        }
    }


    const [stackDetail, setStackDetail] = useState({})

    const nftstackdetails = async () => {
        if (web3main && acc) {
            const accounts = await web3main.eth.getAccounts();
            ////////console.log("nmn");
            let userwalletaddresss = accounts[0];
            // web3main = new Web3(web3main && acc);
            let swaping = new web3main.eth.Contract(stack, nftStakingContract1)

            swaping.methods.nftStakeDetails(id).call({ from: userwalletaddresss })
                .then((fees) => {

                    setstcheck(fees[2])
                    setStackDetail(fees);
                }).catch(err => {
                    ////console.log(err)
                })
        }
    }
    ////console.log('st', stackDetail)
    const rewardsearnnft = async () => {
        if (web3main && acc) {
            const accounts = await web3main.eth.getAccounts();
            //  ////////console.log(accounts);
            let userwalletaddresss = accounts[0];
            // web3main = new Web3(web3main && acc);
            let swaping = new web3main.eth.Contract(stack, nftStakingContract1)
            swaping.methods.rewardsearn(id).call({ from: userwalletaddresss })
                .then((fees) => {
                    ////////console.log("reward", fees);
                }).catch()

        }
    }
    //////console.log(fdata)
    return (
        <div className="length m-0">
            {
                fdata ?
                    <>
                        <section className="item-details-area">
                            <div className="container">
                                <div className="row justify-content-between mt-5">
                                    <div className="col-12 col-lg-5">
                                        <div className="item-info">
                                            <div className="item-thumb coll-img text-center">
                                                <img src={`https://loopart.mypinata.cloud/ipfs/${fdata ? fdata[6] : null}`} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 mt-5">
                                        {/* Content */}
                                        <div className="content mt-5 mt-lg-0">
                                            <h3 style={{ fontSize: '60px', color: 'orange', textTransform: 'capitalize', fontFamily: "Georgia, 'Times New Roman', Times, serif" }} className="m-0">{fdata[1]}</h3>
                                            <p style={{ fontSize: '20px', textTransform: 'capitalize', fontFamily: "Georgia, 'Times New Roman', Times, serif" }}>{(fdata[5])}</p>
                                            {/* Owner */}
                                            <div className="owner d-flex align-items-center">
                                                <span>Owned By</span>
                                                <p className="owner-meta no-hover d-flex align-items-center ml-3" >
                                                    <img className="avatar-sm rounded-circle mx-3 " style={{ width: '50px' }} src={`https://loopart.mypinata.cloud/ipfs/${fdata ? fdata[6] : null}`} alt="" />
                                                    <h6 className="ml-2">{fdata[3]}</h6>
                                                </p>
                                            </div>
                                            {/* Item Info List */}
                                            <div className="item-info-list mt-4">
                                                <div className='d-flex align-items-center'>
                                                    <p style={{ fontSize: '20px', textTransform: 'capitalize', fontFamily: "Georgia, 'Times New Roman', Times, serif" }}>
                                                        Minted Amount:
                                                    </p>
                                                    <h6 className="ml-2">
                                                        {fdata[10]}
                                                    </h6>

                                                </div>
                                            </div>

                                            <div className="item-info-list mt-4">
                                                <div className='d-flex align-items-center'>
                                                    <p style={{ fontSize: '20px', textTransform: 'capitalize', fontFamily: "Georgia, 'Times New Roman', Times, serif" }}>
                                                        Stacked Amount:
                                                    </p>
                                                    <h6 className="ml-2">
                                                        {stackDetail[5]}
                                                    </h6>

                                                </div>
                                            </div>

                                            <div className="item-info-list mt-4">
                                                <div className='d-flex align-items-center'>
                                                    <p style={{ fontSize: '20px', textTransform: 'capitalize', fontFamily: "Georgia, 'Times New Roman', Times, serif" }}>
                                                        Stacked Time:
                                                    </p>
                                                    <h6 className="ml-2">
                                                        {stackDetail[1]}(s)
                                                    </h6>

                                                </div>
                                            </div>
                                            <div className="item-info-list mt-4">
                                                <div className='d-flex align-items-center'>
                                                    <p style={{ fontSize: '20px', textTransform: 'capitalize', fontFamily: "Georgia, 'Times New Roman', Times, serif" }}>
                                                        Rewards:
                                                    </p>
                                                    <h6 className="ml-2">
                                                        {parseFloat(stackDetail[4] / 1e9)}
                                                    </h6>

                                                </div>
                                            </div>

                                            {
                                                fdata[8] ?
                                                    <div style={{
                                                        backgroundColor: '#141324',
                                                        border: '2px solid rgba(255, 255, 255, 0.12)',
                                                        padding: '10px 10px',

                                                    }}
                                                        className='card no-hover'
                                                    >
                                                        <div className="form-group mt-3">
                                                            <input
                                                                type="text" className="form-control" name="name" placeholder="Stake Amount"
                                                                style={
                                                                    {
                                                                        backgroundColor: '#09080D',
                                                                        color: "#8E8E8E",
                                                                        boxShadow: '0px 5px 10px rgba(0,0,0,12)',
                                                                        border: 'none',
                                                                        padding: '10px'
                                                                    }}
                                                                // placeholder="Please enter the amount of NFT"
                                                                value={amountToStaking} onChange={(e) => setAmountTostaking(e.target.value)}
                                                            >
                                                            </input>
                                                            {stakingError ? <p className='text-danger mt-3'>Field is required</p> : null}
                                                        </div>
                                                        <button style={{ border: 'none', outline: 'none', color: 'white', padding: '7px', borderRadius: '999px' }} className="d-block btn btn-bordered-white w-100 mt-4" onClick={nftstack}>Stacking</button>
                                                        {

                                                            stcheck != zeroAddress ?
                                                                <div className='no-hover'>
                                                                    <div className="form-group mt-3">
                                                                        <input
                                                                            placeholder="Unstake Amount"
                                                                            className="form-control"
                                                                            style={
                                                                                {
                                                                                    backgroundColor: '#09080D',
                                                                                    color: "#8E8E8E",
                                                                                    boxShadow: '0px 5px 10px rgba(0,0,0,12)',
                                                                                    border: 'none',
                                                                                    padding: '10px'
                                                                                }}
                                                                            // placeholder="Please enter the amount of NFT"
                                                                            value={amountToUnstaking} onChange={(e) => setAmountToUnstaking(e.target.value)}>

                                                                        </input>
                                                                        {unstakingError ? <p className='text-danger mt-3'>Field is required</p> : null}
                                                                    </div>

                                                                    <button style={{ border: 'none', outline: 'none', color: 'white', padding: '10px', borderRadius: '999px' }} className="d-block btn btn-bordered-white w-100 mt-4" onClick={nftlock11} >Unstack</button>
                                                                </div> : null

                                                        }
                                                    </div> : ''
                                            }
                                        </div>
                                    </div>
                                    <Modal
                                        show={show}
                                        onHide={handleClose}
                                        backdrop="static"
                                        keyboard={false}
                                    >

                                        <Modal.Body>


                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}><Spinner animation="grow" variant="dark" />
                                                <Spinner animation="grow" variant="dark" />
                                                <Spinner animation="grow" variant="dark" />
                                                <Spinner animation="grow" variant="dark" />
                                                <Spinner animation="grow" variant="dark" />
                                                <Spinner animation="grow" variant="dark" />
                                            </div>






                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        </section>
                    </> : <div style={{ height: '80vh', display: 'flex', paddingTop: '50vh', justifyContent: 'center', alignContent: 'center' }}>
                        <Spinner animation="grow" variant="light" />
                        <Spinner animation="grow" variant="light" />
                        <Spinner animation="grow" variant="light" />
                        <Spinner animation="grow" variant="light" />
                        <Spinner animation="grow" variant="light" />
                    </div>
            }
        </div>
    );
}


export default withRouter(Staking);