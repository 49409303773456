import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import nft from '../../abi/abi.json'
import {
    csdogeToken,
    sitelink
} from '../../config';
import stack from '../../abi/stack.json'
import erc20 from '../../abi/ERC20.json'

function Mystack({ acc, web3main, nftMintingContract1, nftStakingContract1 }) {
    // //console.log("address", addrs)
    const location = useLocation()
    // const fdata = location.state
    const { id } = useParams()
    //console.log("Acccc", acc)
    const [account, setaccount] = useState()
    useEffect(() => {
        // nftinfo(id)
        if (acc && web3main) {
            nfttotal()
            totalSupply()
        }

    }, [acc, web3main])


    const [show, setShow] = useState(false);
    const [fdata, setfdata] = useState([])
    const handleClose = () => setShow(false);
    const [tottoken, settottoken] = useState()
    const [myreward, setMyreward] = useState();
    const [nftStaked, setNftStaked] = useState(0);
    const [unclaimedReward, setUnclaimedReward] = useState();
    const [totalReward, setTotalReward] = useState();
    const [stakingTime, setStakingTime] = useState();
    const [rewardRate, setRewardRate] = useState();

    const nfttotal = async () => {
        if (web3main) {
            const accounts = await web3main.eth.getAccounts();
            setaccount(accounts[0])
            let userwalletaddresss = accounts[0];
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)
            swaping.methods.tokenidmint().call({ from: userwalletaddresss })
                .then((length) => {
                    for (let i = 1; i <= Number(length); i++) {
                        nftstackdetails(i)
                    }

                }).catch()

        }
    }

    const nftinfo = async (id) => {
        if (web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)

            swaping.methods.nftInformation(id[0]).call({ from: userwalletaddresss })
                .then((fees) => {
                    getallasset({ ...id, 9: fees[6], 10: fees[1] })



                }).catch()

        }
    }
    const getallasset = (data) => {
        setfdata(old => [
            ...old, data
        ])

    }
    const nftstack = async () => {
        setShow(true)
        if (web3main) {

            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)
            swaping.methods.nftstake(id).send({ from: userwalletaddresss })
                .then((fees) => {
                    //console.log(fees);
                    nftlock()
                }).catch()

        }
    }
    const totalSupply = async (id) => {
        if (web3main) {
            const accounts = await web3main.eth.getAccounts();
            //  //console.log(accounts);
            let userwalletaddresss = accounts[0];
            // window.web3 = new Web3(window.ethereum);
            let swaping = new web3main.eth.Contract(erc20, csdogeToken)

            swaping.methods.totalSupply().call({ from: userwalletaddresss })
                .then((fees) => {
                    //console.log("cvcv", fees);
                    settottoken(fees)
                }).catch()

        }
    }

    const nftlock = async () => {
        if (web3main) {
            const accounts = await web3main.eth.getAccounts();
            //  //console.log(accounts);
            let userwalletaddresss = accounts[0];
            // window.web3 = new Web3(web3main);
            let swaping = new web3main.eth.Contract(stack, nftStakingContract1)
            swaping.methods.stakenft(id).send({ from: userwalletaddresss })
                .then((fees) => {
                    //console.log(fees);
                    setShow(false)
                }).catch()

        }
    }

    const nftstackdetails = async (id) => {
        
        if (web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            let swaping = new web3main.eth.Contract(stack, nftStakingContract1)
            
            swaping.methods.nftStakeDetails(id).call({ from: userwalletaddresss })
                .then((fees) => {
                       
                    if (fees[0] === "0") {

                    } else {
                        rewardsearnnft(fees)
                    }
                }).catch()

        }
    }

    const rewardsearnnft = async (id) => {
        if (web3main) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            //  //console.log(accounts);
            let userwalletaddresss = accounts[0];
            // window.web3 = new Web3(web3main);
            let swaping = new web3main.eth.Contract(stack, nftStakingContract1)
            swaping.methods.PoolName().call({ from: userwalletaddresss })
                .then((result) => {
                    setPoolName(result)
                })

            swaping.methods.PoolInfoperiodStaking().call({ from: userwalletaddresss })
                .then((result) => {
                    let peroid = result;
                    swaping.methods.PoolInfoTotalReward().call({ from: userwalletaddresss })
                        .then((total) => {

                            swaping.methods.PoolInfototalStaked().call({ from: userwalletaddresss })
                                .then((amount) => {
                                    
                                    debugger
                                    if (peroid != "0" && amount != "0"){
                                        let reward = total / (24 * 3600 * amount * peroid * 1e9);
                                        reward = Math.floor(reward * 100000000) / 1000000000;
                                        setRewardRate(reward)
                                    }
                                    else{
                                        setRewardRate(0)
                                    }   
                                    
                                })
                        })

                })
            swaping.methods.userStakeInfo(userwalletaddresss).call({ from: userwalletaddresss })
                .then((result) => {
                    debugger
                    setNftStaked(result[0]);
                    setMyreward(parseFloat(result[2] / 1e9));
                    setTotalReward(parseFloat(result[3] / 1e9))
                    setUnclaimedReward(parseFloat(result[1] / 1e9))
                    setStakingTime(parseFloat(result[4]))

                })
                .catch(err =>
                    console.log(err)
                )
            swaping.methods.calculateRewardsPerToken(userwalletaddresss, id[0]).call({ from: userwalletaddresss })
                .then((fees) => {
                    //console.log("reward", { ...id, 8: fees });
                    nftinfo({ ...id, 8: fees });
                }).catch()

        }
    }

    const fildata = fdata?.filter(p => p[2]?.toLowerCase() === account?.toLowerCase())
    const totalreward = fdata?.reduce((pre, curr) => ((Number(curr[8]) + pre) / 1000000000), 0)
    // const myreward = fildata?.reduce((pre, curr) => ((Number(curr[8]) + pre) / 1000000000), 0)
    //console.log('fff', fildata)
    const [poolName, setPoolName] = useState();
    const claimRewards = async () => {
        if (web3main) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            //  //console.log(accounts);
            let userwalletaddresss = accounts[0];
            // window.web3 = new Web3(web3main);
            let swaping = new web3main.eth.Contract(stack, nftStakingContract1)

            swaping.methods.claimRewards().send({ from: userwalletaddresss })
                .then(() => {
                    setShow(false)
                    window.location.reload()
                }).catch()
        }
    }
    return (
        <div className='main'>
            <div className='container'>
                <div className='row mt-3'>
                    <div className='col-md-4 col-12 mt-3 d-flex justify-content-center align-items-center'>
                        <div className='card d-flex justify-content-center w-100 px-3 py-6'>
                            <h3 style={{ color: 'lightyellow', fontStyle: 'bold', textAlign: 'center', textTransform: 'uppercase' }}>{poolName}</h3>
                        </div>
                    </div>

                    <div className='col-md-2 col-12 mt-3 d-flex justify-content-center align-items-center ml-3'>
                        <button style={{ border: 'none', outline: 'none', color: 'white', padding: '10px', borderRadius: '999px' }} className="d-block btn btn-bordered-white w-100" onClick={claimRewards}>Claim Rewards</button>
                    </div>
                    <div className='col-md-12 col-12 mt-3'>
                        <div className='card d-flex flex-row justify-content-between px-3'>

                            <div className='mt-3'>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Total staked</p>
                                <p className='d-flex justify-content-center align-items-center text-center' style={{ fontSize: '15px', fontWeight: 'bold', color: 'rgb(153, 183, 255)' }}>{nftStaked}</p>

                            </div>
                            <div className='mt-3'>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Current reward rate</p>
                                <p className='d-flex justify-content-center align-items-center text-center' style={{ fontSize: '15px', fontWeight: 'bold', color: 'rgb(153, 183, 255)' }}>{rewardRate}(csdoge)</p>
                            </div>
                            <div className='mt-3'>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Current staking time</p>
                                <p className='d-flex justify-content-center align-items-center text-center' style={{ fontSize: '15px', fontWeight: 'bold', color: 'rgb(153, 183, 255)' }}>{stakingTime}(s)</p>
                            </div>
                            <div className='mt-3'>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Current Reward</p>
                                <p className='d-flex justify-content-center align-items-center text-center' style={{ fontSize: '15px', fontWeight: 'bold', color: 'rgb(153, 183, 255)' }}>{myreward}({stakingTime + '*' + rewardRate + "*" + nftStaked})</p>

                            </div>

                            <div className='mt-3'>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Total Reward from this pool</p>
                                <p className='d-flex justify-content-center align-items-center text-center' style={{ fontSize: '15px', fontWeight: 'bold', color: 'rgb(153, 183, 255)' }}>{totalReward}</p>

                            </div>

                        </div>
                    </div>
                    {/* <div className='col-md-8 col-12 mt-3'>
                        <div className='card d-flex flex-row justify-content-between px-3'>
                            <div className=''>
                            <p>APR: Testing Stage</p>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>1500%</p>

                            </div>
                            <div className=''>
                            <p>Applied only to</p>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>“SIMPLY EGG NFT”</p>

                            </div>
                            <div className=''>
                                <p>TOTAL REWARDS</p>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>{totalreward}</p>

                            </div>
                            <div className=''>
                                <p>TOKEN SUPPLY</p>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>{((tottoken / 1000000000) - 76948912418245500)}</p>

                            </div>


                        </div>
                    </div> */}
                    {/* <div className='col-md-3 col-12 mt-3'>
                        <div className='card d-flex flex-row justify-content-between px-3'>
                            <div className=''>
                                <p>BABY EARNED</p>
                                <p>0</p>

                            </div>
                            <div className=''>
                                <button>Collect</button>

                            </div>

                        </div>
                    </div> */}
                </div>
                <div className='row mt-3'>


                    {
                        fildata?.map((v) => {
                            return <div className='col-md-6 col-xl-4 col-12 mb-3'>
                                <div className='card shadow'>
                                    <div className='img p-3'>
                                        <a href={`/staking/${v[0]}?nftMintingContract1=` + nftMintingContract1 + '&nftStakingContract1=' + nftStakingContract1}>
                                            <img src={`https://loopart.mypinata.cloud/ipfs/${v ? v[9] : null}`} className='img-fluid' />
                                        </a>

                                    </div>
                                    <div className='cardbody'>
                                        <h3 className='text-center'>{v ? v[10] : null}</h3>
                                        <div className='d-flex justify-content-between px-3'>
                                            <p style={{ fontSize: '20px' }}>Id:</p>
                                            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>{v[0]}</p>
                                        </div>
                                        <div className='d-flex justify-content-between px-3'>
                                            <p style={{ fontSize: '20px' }}>Category:</p>
                                            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>NFT</p>
                                        </div>
                                        <div className='d-flex justify-content-between px-3'>
                                            <p style={{ fontSize: '20px' }}>Rewards:</p>

                                            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>{parseFloat(v[8] / 1e9)}</p>
                                        </div>
                                        < div className='onbtn'>
                                            <a href={`${sitelink}/item-details/${v[0]}`}>
                                                <button>View NFTs on Nftartland</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }

                </div>
            </div>

        </div>
    )
}

export default Mystack
