import React  from "react"
import { useState } from "react";
import { useEffect } from "react";
import {Modal, Button, Form} from 'react-bootstrap';
import Web3 from "web3";
const DialogModal = (props) => {
    const {open, handleModalClose,setAddressToSend, setAmountToBuy} = props;
    const [number, setNumber] = useState(0);
    const [names, setNames] = useState([])
    const [error, setError] = useState(false)
    const [str, setStr ] = useState();
    const change = (e) => {
        setStr(e.target.value)
    }
    const validate = (str) => {
        let tempArray = str.split(/[\n,]+/)
        // let tempArray = str;
        let tempNameArray = [];
        for (let i = 0 ; i< tempArray.length;i++)
        if (tempArray[i]){
            
            if (Web3.utils.isAddress(tempArray[i]))
            {
                tempNameArray.push(tempArray[i])
                setNames(tempNameArray)
                setNumber(tempNameArray.length)
            }
            else{
                // debugger
                return false;
            }
        }
        return tempNameArray
    }
    const submit = () => {

        let tempNameArray = validate(str)
        if (tempNameArray)
        {
            setAddressToSend(tempNameArray);
            setAmountToBuy(tempNameArray.length)
            handleModalClose();
            setError(false)
            setNumber(0)
        }
        else{
            setError(true)
        }
    }
    useEffect(()=>{
        console.log('@@@@@@@@@@@@@@@@',props)
    },[props])
    const handleClose = () => {
        setError(false)
        setAmountToBuy(0)
        setAddressToSend([]);
        setNames([])
        setNumber(0)
        handleModalClose();
    }
    return(
        <Modal 
            show={open} onHide={handleClose} animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Please Type Address</Modal.Title>
                
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <textarea placeholder="Please Type the Addresses" rows="10" onChange={change} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {error?
                        <Form.Text className="text-danger">Please type valid address</Form.Text>
                        :''}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button className="btn btn-bordered-white mt-3 " onClick={handleClose}>
                Close
            </Button>
            <Button className="btn btn-bordered-white mt-3 " onClick={submit}>
                OK
            </Button>
            </Modal.Footer>
      </Modal>
    )
}
export default DialogModal