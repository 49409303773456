// require('dotenv').config();
// const { REACT_APP_NET_TYPE } = process.env;
const REACT_APP_NET_TYPE = "mainnet"
//bsc 
export const nftMintingContract1 = REACT_APP_NET_TYPE === "mainnet" ? "0x2E92100f38394660Cf889b0c19a3F4c9DC5E862F" : "0x006027AF5FBb8333Ca586E86E592c0a38df4EFBB"

//sepolia
//export const nftMintingContract1 = REACT_APP_NET_TYPE === "mainnet" ? "0x40298Ccb81F70aBb4495a3f9de6caBB48965D99F" : "0x4772ec63417D5c0E9D3926d32e0453998b06aa66"

export const nftMintingContract2 = REACT_APP_NET_TYPE === "mainnet" ? "0x5a1c82bBB39c7bF9d963999798cFb7727367bbBB" : "0xe0CA4b88138e22FBf4Eb34663CFDF0fcb2F3C361"

//bsc
export const nftStakingContract1 = REACT_APP_NET_TYPE === "mainnet" ? "0x861Ddd85255dd10863eD74F96c72911DDD80289B" : "0x8373617E2cc25BA3160717F2B9986B5264886896"

//sepolia
//export const nftStakingContract1 = REACT_APP_NET_TYPE === "mainnet" ? "0x6291CC30dEfF5697fd3F4373CF09bD02397D050F" : "0x3F57E01EC606657917370217057a8b4C89f2dEA0"

export const nftStakingContract2 = REACT_APP_NET_TYPE === "mainnet" ? "0xF9C4bb5Bd54e97E76924789f11A945a8a83fAB12" : "0x9A5aB7286f26475199b4c2f65d57EBF2c14e4683"
export const csdogeToken = REACT_APP_NET_TYPE === "mainnet" ? "0x6eAbBB5c4FDA0033936d07Cf1A444e8816C009FC" : '0x93D689C22f9a38C7a9661da702e4F055D52B9647'
//export const csdogeToken = REACT_APP_NET_TYPE === "mainnet" ? "0x6eAbBB5c4FDA0033936d07Cf1A444e8816C009FC" : '0x8f285D8201f1DCC3cCa7d4D3e89445F0f41E894f'
// export const sitelink = "https://csdoge-staking.netlify.app";
export const sitelink = "https://csdoge-staking-bsc.netlify.app";

export const chainIdString = REACT_APP_NET_TYPE === "mainnet" ? '0x38' : '0x61';
//export const chainIdString = REACT_APP_NET_TYPE === "mainnet" ? '0x38' : '0xaa36a7';
export const chainIdHex = REACT_APP_NET_TYPE === "mainnet" ? 0x38 : 0x61;
//export const chainIdHex = REACT_APP_NET_TYPE === "mainnet" ? 0x38 : 0xaa36a7;
 export const chainIdDecimal = REACT_APP_NET_TYPE === "mainnet" ? 56 : 97;
//export const chainIdDecimal = REACT_APP_NET_TYPE === "mainnet" ? 56 : 11155111;

export const rpcUrl = REACT_APP_NET_TYPE === "mainnet" ? "https://bsc-dataseed.binance.org/" : "https://data-seed-prebsc-1-s3.binance.org:8545"

// admin wallet
export const adminWallet = REACT_APP_NET_TYPE === "mainnet" ? "0x5A5Cfe53f20a76dcef88e344052290e7e7A8d430" : "0x494B5c83086d7A08e36259cD3bfbC12100DD6C80";

export const netCapitalizeString = REACT_APP_NET_TYPE === "mainnet" ? 'Mainnet' : 'Testnet';