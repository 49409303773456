import React, { useEffect, useState } from 'react'
import Web3 from "web3";
import Web3Modal from "web3modal";
import Portis from "@portis/web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { providers, ethers } from "ethers";
import {
    rpcUrl,
    chainIdDecimal,
} from '../../config';
import logo from './1200.png'
// import Web3 from 'web3'
import { Modal, Button } from 'react-bootstrap'
let web3Modal;
let provider;
let selectedAccount;

function init() {
    const providerOptions = {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                rpc: {
                    chainIdDecimal: rpcUrl,
                },
                chainId: chainIdDecimal
            }
        },


    };

    web3Modal = new Web3Modal({
        network: "testnet", // optional
        cacheProvider: true, // optional
        providerOptions // required
    });

    window.w3m = web3Modal;
}

async function fetchAccountData() {
    const web3Provider = new ethers.providers.Web3Provider(provider);
    const signer = web3Provider.getSigner();
    selectedAccount = await signer.getAddress();
    //console.log(selectedAccount);

    return selectedAccount;
}

async function refreshAccountData() {
    await fetchAccountData(provider);
}

async function onConnect() {
    //console.log("Opening a dialog", web3Modal);
    try {
        provider = await web3Modal.connect({ cacheProvider: true });
    } catch (e) {
        //console.log("Could not get a wallet connection", e);
        return;
    }

    provider.on("accountsChanged", (accounts) => {
        //console.log('chainchan',accounts)
        fetchAccountData();
        // window.location.reload()
    });

    provider.on("chainChanged", (chainId) => {
        fetchAccountData();
        window.location.reload()
    });

    provider.on("networkChanged", (networkId) => {
        fetchAccountData();
    });
    window.location.reload()

    await refreshAccountData();
}

async function disconnet() {
    try {
        await web3Modal.clearCachedProvider();
        window.location.reload()
    } catch (e) {
        return;
    }

}
const Header = ({ change, web3m, provider1 }) => {
    const [acc, setacc] = useState()
    const [isDarkMode, setIsDarkMode] = useState(() => true);
    const [accountid, setaccountid] = useState()
    useEffect(() => {
        localStorage.setItem('dark', isDarkMode)

    }, [isDarkMode])
    useEffect(async () => {
        if (acc) {
            provider = await web3Modal.connect();
            const web3 = new Web3(provider);
            const accounts = await web3.eth.getAccounts();
            web3m(web3)
            provider1(provider)
            setaccountid(accounts[0])
        }
    }, [acc]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    useEffect(() => {
        init();
        change(false)
        if (web3Modal.cachedProvider) {
            setacc(true)
            change(true)
        }
    }, []);




    return (
        <header id="header">
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand">
                <div className="container header">
                    {/* Navbar Brand*/}
                    <a className="navlogo" href="/">
                        <img className="navbar-brand-sticky" src={logo} alt="sticky brand-logo" />
                    </a>
                    <div className="ml-auto" />
                    {/* Navbar */}
                    {/* Navbar Icons */}
                    <ul className="navbar-nav icons">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#search">
                                <i className="fas fa-search" />
                            </a>
                        </li>
                    </ul>
                    {/* Navbar Toggler */}
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                <i className="fas fa-bars toggle-icon m-0" />
                            </a>
                        </li>
                    </ul>
                    {/* Navbar Action Button */}
                    <ul className="navbar-nav action">
                        <li className="nav-item ml-3">
                            {acc ?
                                <>  <a className="btn btncon ml-lg-auto btn-bordered-white" style={{ fontSize: '15px', width: '180px', wordBreak: 'break-all' }}>{accountid?.slice(0, 3)}.....{accountid?.slice(-3)}<br /></a>
                                    <a onClick={disconnet} className="btnlog btn ml-lg-2 btn-bordered-white">Logout</a>
                                </> : <a onClick={onConnect} className="btn ml-lg-auto btn-bordered-white"><i className="icon-wallet mr-md-2" />Wallet Connect</a>

                            }
                            {/* <button onClick={connectWallet} className="btn ml-lg-auto btn-bordered-white"><i className="icon-wallet mr-md-2" />Wallet Connect</button> */}
                        </li>
                    </ul>
                </div>

                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >

                    <Modal.Body>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <h3>Connect To Wallets</h3>
                            <button style={{ borderRadius: '5px', border: 'none', padding: '5px 10px', outline: "none" }} onClick={onConnect} ><img src="https://metamask.io/images/mm-logo.svg" className="img-fluid" /></button>
                        </div>

                    </Modal.Body>
                </Modal>
            </nav>
        </header>
    );
};

export default Header;