import React, { useState } from 'react';
import AuthorProfile from "../AuthorProfile/AuthorProfile";
import Web3 from 'web3'
import nft from '../../abi/nft.json'
import { useHistory } from 'react-router';
import axios from 'axios'
import fromExponential from 'from-exponential'
import { useParams } from 'react-router-dom';
import { Modal, Spinner } from 'react-bootstrap'
import abi from '../../abi/nft.json'
const key = process.env.REACT_APP_PINATA_KEY;
const secret = process.env.REACT_APP_PINATA_SECRET;

function Createcsdoge({ acc, web3main }) {
    //console.log('css', acc)

    const [img, setimg] = useState();
    let { id } = useParams();
    const [displayimage, setdisplayimg] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [copies, setcopies] = useState()
    const [ownername, setownername] = useState()
    const [assetname, setassetname] = useState()
    const [assetdescrip, setassetdescrip] = useState()
    const history = useHistory()
    const [price, setprice] = useState()
    const [checkval, setcheckval] = useState(true)

    const choosepic = (e) => {
        setimg(e.target.files[0])
        const file = e.target.files[0];
        const render = new FileReader()
        render.onload = () => {
            if (render.readyState === 2) {
                setdisplayimg(render.result)
            }
            const reader = new window.FileReader()
            reader.readAsArrayBuffer(file)
            reader.onloadend = () => {
                const buffer = Buffer.from(reader.result);
                //   //console.log('buffer', buffer)
            }
        }
        render.readAsDataURL(e.target.files[0])
    }
    const pinFileToIPFS = async (file) => {
        const url = "https://api.pinata.cloud/pinning/pinFileToIPFS";
        debugger
        if(img){
            try {
                const formData = new FormData();
                formData.append("file", img);
                const resFile = await axios({
                    method: "post",
                    url: url,
                    data : formData,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'pinata_api_key': `${process.env.REACT_APP_PINATA_KEY}`,
                        'pinata_secret_api_key': `${process.env.REACT_APP_PINATA_SECRET}`,
                    }
                }) 
    
                const ImgHash = resFile.data.IpfsHash;
                return {success : true , ImgHash:ImgHash}
    
            } catch (error) {
                debugger
                return {success : false , message : "pinning File failed"}
            }
        }
    }

    const pinJSONToIPFS = async(JSONBody) => {
        const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
        
        if (JSONBody.image) {
                const {success , ImgHash} = await pinFileToIPFS(JSONBody.image);
                

                if(success){
                    JSONBody.image = "https://gateway.pinata.cloud/ipfs/" +ImgHash;
                    debugger
                    return axios
                    .post(url, JSONBody, {
                        headers: {
                            pinata_api_key: key,
                            pinata_secret_api_key: secret,
                        }
                    })
                    .then(function (response) {
                       return {
                           success: true,
                           pinataUrl: "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash,
                           imghash: ImgHash
                       };
                    })
                    .catch(function (error) {
                        return {
                            success: false,
                            message: error.message,
                        }
            
                });
                }
                else {
                    return {success : false , message : 'JSON pinning failed'}
                }
        }
    
    };  

    const submit = async (e) => {
        debugger
        e.preventDefault()
        const JSONBody = {
            name: assetname,
            description: assetdescrip,
            image: img,
            attributes: [
                {
                    trait_type: "name",
                    value: assetname
                },
                {
                    trait_type: "description",
                    value: assetdescrip
                }
            ]
        }

        const response = await pinJSONToIPFS(JSONBody);
        mintcsdoge(response);

    }
    const mintcsdoge = async (e) => {
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            // settokenid(accounts)
            setShow(true)
            let userwalletaddresss = accounts[0];
            const web3Main = await new Web3(window.ethereum);
            let Contract = new web3Main.eth.Contract(abi, id);
            let collectionid = 1;
            let imghash = e.imghash;
            let metadataUrl = e.pinataUrl;
            debugger
            if (e)
            {
                Contract.methods.createcsdoge(collectionid, userwalletaddresss, imghash, assetname, ownername,assetdescrip,parseInt(copies), metadataUrl ).send({ from: userwalletaddresss })
                    .then(async (fees) => {

                        if (fees.status === true) {
                          
                            nftidn()
                        } else {
                            alert('failed')
                        }
                    }).catch((err) => {

                        // settokenid('')
                        setShow(false)
                        // setpay('')
                        // //console.log('erre')
                    })
            }


        }
    }
    const nftidn = async () => {
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            //  //console.log(accounts);
            let userwalletaddresss = accounts[0];
            //console.log('ack', accounts)
            // window.web3 = new Web3(window.ethereum);
            //console.log('ccc', userwalletaddresss)
            let swaping = new web3main.eth.Contract(nft, id)

            swaping.methods.tokenidmint().call({ from: userwalletaddresss })
                .then((id) => {
                    // //console.log('iddnew',String(Number(id)+1));
                    //console.log("idd", id)
                    fixedsale(id)

                })
                .catch()

        }
    }
    const fixedsale = async (tokenid) => {
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            setShow(true)
            let userwalletaddresss = accounts[0];
            let swaping = new web3main.eth.Contract(nft, id)
            if (checkval) {
                let amount = web3main.utils.toBN(fromExponential(((parseFloat(price)) * Math.pow(10, 9))));
                swaping.methods.fixedsales(tokenid, amount, checkval).send({ from: userwalletaddresss })
                    .then((length) => {
                        if (length.status === true) {
                            setShow(false)
                            history.push('/')
                        } else {
                            alert('failed')
                        }
                    })
                    .catch((err) => {
                        // settokenid('')
                        setShow(false)
                        // setpay('')
                    })

            } else {
                let amount = web3main.utils.toBN(fromExponential(((parseFloat(price)) * Math.pow(10, 18))));
                //console.log('mainamout', amount)

                swaping.methods.fixedsales(tokenid, amount, checkval).send({ from: userwalletaddresss })
                    .then((length) => {
                        //console.log(length);
                        if (length.status === true) {
                            // settokenid('')
                            // setpay('suceess')
                            // setShow(true)
                            setShow(false)
                            history.push('/')
                        } else {
                            alert('failed')
                        }
                    })
                    .catch((err) => {
                        // settokenid('')
                        setShow(false)
                        // setpay('')
                    })

            }


        }
    }


    return (
        <section className="author-area">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-md-4">
                        {/* Author Profile */}
                        <AuthorProfile sendpic={displayimage} />
                    </div>
                    <div className="col-12 col-md-7">
                        {/* Intro */}
                        <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                            <div className="intro-content">
                                <span>Get Started</span>
                                <h3 className="mt-3 mb-0">Create Csdoge</h3>
                            </div>
                        </div>
                        {/* Item Form */}
                        <form className="item-form card no-hover" onSubmit={submit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="input-group form-group">
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" onChange={choosepic} id="inputGroupFile01" />
                                            <label className="custom-file-label" htmlFor="inputGroupFile01">Choose file</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group mt-3">
                                        <input type="text" className="form-control" name="csname" placeholder="Cs Owner Name" onChange={(e) => setownername(e.target.value)} required="required" />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group mt-3">
                                        <input type="text" className="form-control" name="dname" onChange={(e) => setassetname(e.target.value)} placeholder="Asset Name" required="required" />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group">
                                        <textarea className="form-control" name="textarea" placeholder="Description" onChange={(e) => setassetdescrip(e.target.value)} cols={30} rows={3} defaultValue={""} />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group ">
                                        <input type="number" className="form-control" name="copies" min="0" placeholder="Copies" onChange={(e) => setcopies(e.target.value)} required="required" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <input type="number" className="form-control" name="price" min="0" step="any" placeholder="Price" onChange={(e) => setprice(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group row">
                                        <div className="col-12 md-6 ">
                                            <label for="html">BNB</label>
                                            <input type="radio" id="bnb" name="fav_language" checked={checkval === false} onClick={() => setcheckval(false)} />
                                        </div>
                                        <div className="col-12 md-6">
                                            <label for="ETH" >CSDOGE</label>
                                            <input type="radio" id="ETH" name="fav_language" checked={checkval === true} onClick={() => setcheckval(true)} />
                                        </div>


                                    </div>
                                </div>

                                <div className="col-12">
                                    <button type='submit' className="btn w-100 mt-3 mt-sm-4" >Create Csdoge</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >

                        <Modal.Body>


                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}><Spinner animation="grow" variant="light" />
                                <Spinner animation="grow" variant="light" />
                                <Spinner animation="grow" variant="light" />
                                <Spinner animation="grow" variant="light" />
                                <Spinner animation="grow" variant="light" />
                                <Spinner animation="grow" variant="light" />
                            </div>



                        </Modal.Body>

                    </Modal>
                </div>
            </div>
        </section>
    );
}


export default Createcsdoge;