import React from "react";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
const StackHeader = ({path}) => {
    const history = useHistory();
    return(
        <div style={{backgroundColor:"#050023"}}>
            <div className="container">
                
                <div style={{display:'flex', gap:'50px'}}>
                        {path == 'mystack' ?
                            <p className="btnlog btn ml-lg-2 btn-bordered-white" style={{ color: 'lightyellow', fontStyle: 'bold', textAlign: 'left', display:'flex',cursor:'pointer', textTransform: 'uppercase', margin:'10px 0px 0px 30px'}} onClick={()=> {history.goBack()}}>
                                <i className="fas fa-arrow-left" style={{marginRight:'20px'}}></i>
                                Staking
                            </p>
                            :
                            <>
                                <Link to="/" className="btnlog btn ml-lg-2 btn-bordered-white" style={{ color: 'lightyellow', fontStyle: 'bold',  textAlign: 'left', display:'flex',cursor:'pointer', textTransform: 'uppercase', margin:'10px 0px 0px 30px' }}>
                                    <i className="fas fa-arrow-left" style={{marginRight:'20px'}}></i>
                                    Buy NFT
                                </Link>
                                <Link to="/mystack" className="btnlog btn ml-lg-2 btn-bordered-white"   style={{ color: 'lightyellow', fontStyle: 'bold', textAlign: 'center', textTransform: 'uppercase', margin:'0px', marginTop:'10px' }}>My Stack</Link>
                            </>
                        }
                           

                </div>
             </div>
        </div>
    )
}

export default StackHeader;