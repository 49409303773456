import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import Web3 from 'web3'
import nft from '../../abi/nft.json'
import { useMoralis, useMoralisQuery } from "react-moralis";
import { Modal, Spinner } from 'react-bootstrap'
import fromExponential from 'from-exponential';
import ERC20 from '../../abi/ERC20.json'
import DialogModal from './DialogModal'
import {
    sitelink,
    csdogeToken,
} from '../../config';

function Csdetail({ acc, web3main }) {
    const location = useLocation()
    // const fdata = location.state
    const { csid } = useParams()
    const { address, stakeid } = useParams();
    const nftMintingContract1 = address;
    const [buyprice, setbuyprice] = useState()
    const [buyaucprice, setaucbuyprice] = useState()
    const [auch, setauch] = useState()
    const [time, settime] = useState()
    const [accountid, setaccountid] = useState()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [cklist, setcklist] = useState()
    const [cspric, setcsprice] = useState()
    const [fdata, setfdata] = useState()
    const [gift, setgift] = useState(false);
    const [amountToSend, setAmountToSend] = useState();
    const [addressToSend, setAddressToSend] = useState();
    const [amountToBuy, setAmountToBuy] = useState(1);
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(false)

    const handleCloseModal = () => {
        setOpen(false);
    };

    useEffect(async () => {
        if (acc && web3main) {

            salenft(csid)
            timer(csid)
            const accounts1 = await web3main.eth.getAccounts();
            setaccountid(accounts1[0])
            tokeninfo(csid)
            salenftprie(csid)
            nftinfo(csid)
        }
    }, [csid, acc, web3main])

    const salenftprie = async (id) => {
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            console.log('ccc', userwalletaddresss)
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)

            swaping.methods.listofsalenft(id).call({ from: userwalletaddresss })
                .then((length) => {
                    const val = {
                        id: id, value: (Number(length[3]))
                    }
                    setcsprice(val)
                })
                .catch()

        }
    }
    
    const salenft = async (id) => {
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            console.log('ccc', userwalletaddresss)
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)

            swaping.methods.listofsalenft(id).call({ from: userwalletaddresss })
                .then((length) => {
                    setbuyprice((Number(length[3])).length > 21 ? (Number(length[3])) / 1000000000000000000000000000000000000 : (Number(length[3])) / 1000000000000000000)
                    setaucbuyprice((Number(length[2])).length > 21 ? (Number(length[2])) / 1000000000000000000000000000000000000 : (Number(length[2])) / 1000000000000000000)
                })
                .catch()

        }
    }

    const bulkSend = (id, flag) => {
        if (addressToSend.length != amountToBuy)
            setError(true)
        else {
            if (amountToBuy)
                mintBatchNFT(id, flag)
        }
    }

    const mintBatchNFT = async (tokenid, boolvalue) => {
        let ckamout = cklist?.ck ? Number(cspric?.value) / 1000000000 : Number(cspric?.value) / 1000000000000000000
        setShow(true)
        if (acc && web3main && ckamout) {

            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            console.log('ccc', userwalletaddresss)
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)
            if (boolvalue) {
                let tokenaddress = csdogeToken;
                const ercContract = await new web3main.eth.Contract(ERC20, tokenaddress);
                let amountADesired = web3main.utils.toBN(fromExponential(parseInt((parseFloat(ckamout * addressToSend.length)) * Math.pow(10, 9))));
                ercContract.methods.approve(nftMintingContract1, amountADesired * amountToBuy).send({ from: userwalletaddresss })
                    .then((res) => {
                        console.log(res);
                        let swaping = new web3main.eth.Contract(nft, nftMintingContract1)
                        // debugger
                        swaping.methods.mintBatchNFT(addressToSend, tokenid).send({ from: userwalletaddresss, value: amountADesired })
                            .then((fees) => {
                                console.log(fees);
                                window.location.reload()
                                setShow(false)
                            }).catch((err) => {
                                setShow(false)
                            })
                    })
                    .catch(() => {
                        setShow(false)

                    })
            }
            else {
                console.log('checked', ckamout)
                let amountIn = web3main.utils.toBN(fromExponential((ckamout * addressToSend.length) * Math.pow(10, 18)));
                console.log('amoutck', amountIn)
                let tokenaddress = '0x0000000000000000000000000000000000000000'
                console.log('checked', tokenid)
                // debugger
                swaping.methods.mintBatchNFT(addressToSend, tokenid).send({ from: userwalletaddresss, value: amountIn })
                    .then((recipt) => {
                        // console.log(recipt);
                        setShow(false)
                        localStorage.removeItem(`buylist${tokenid}`)
                        window.location.reload(true)
                    })
                    .catch((err) => {
                        console.log(err)
                        setShow(false)

                    })

            }
        }
    }

    const timer = async (id) => {
        // console.log('saa',id)
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            console.log('ccc', userwalletaddresss)
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)


            swaping.methods.timing(id).call({ from: userwalletaddresss })
                .then((fees) => {

                    var day = Math.floor(fees / 86400)
                    var hr = Math.floor((fees - day * 86400) / 3600)
                    var minutesout = Math.floor((fees - day * 86400 - hr * 3600) / 60);
                    // console.log("hr",hr)
                    // console.log("day",day)
                    // console.log("min",minutesout)
                    settime({ id: id, d: day, h: hr, m: minutesout })


                }).catch()

        }
    }

    const nftinfo = async (id) => {
        // console.log('4')
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            console.log('ccc', userwalletaddresss)
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)

            swaping.methods.nftInformation(id).call({ from: userwalletaddresss })
                .then((fees) => {
                    setfdata(fees)

                    console.log("info", fees)


                }).catch()

        }
    }

    const tokeninfo = async (tokenid) => {
        if (acc && web3main) {
            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            console.log('ccc', userwalletaddresss)
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)
            swaping.methods.csdogeinfo(tokenid).call({ from: userwalletaddresss })
                .then((fees) => {
                    const val = {
                        id: tokenid,
                        ck: fees[0],
                        copy: Number(fees[1]),
                        total: Number(fees[2])
                    }
                    setcklist(val)
                    console.log('ccs', val)


                })
                .catch()

        }
    }

    const buycopiesnft = async (tokenid, boolvalue) => {
        let ckamout = cklist?.ck ? Number(cspric?.value) / 1000000000 : Number(cspric?.value) / 1000000000000000000
        setShow(true)
        console.log('bool', boolvalue)
        console.log('boolck', ckamout)

        if (acc && web3main && ckamout) {

            const accounts = await web3main.eth.getAccounts();
            let userwalletaddresss = accounts[0];
            console.log('ccc', userwalletaddresss)
            let ownerAddress = gift ? addressToSend : userwalletaddresss
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)
            if (boolvalue) {
                let tokenaddress = csdogeToken;
                const ercContract = await new web3main.eth.Contract(ERC20, tokenaddress);
                let amountADesired = web3main.utils.toBN(fromExponential(parseInt((parseFloat(ckamout)) * Math.pow(10, 9))));
                ercContract.methods.approve(nftMintingContract1, amountADesired * amountToBuy).send({ from: userwalletaddresss })
                    .then((res) => {
                        console.log(res);
                        let swaping = new web3main.eth.Contract(nft, nftMintingContract1)
                        swaping.methods.mintNFT(ownerAddress, tokenid, 0, amountToBuy, web3main.utils.fromAscii(""))
                            .send({ from: userwalletaddresss })
                            .then((fees) => {
                                console.log(fees);
                                window.location.reload()
                            }).catch((err) => {
                                setShow(false)
                            })
                    })
                    .catch(() => {
                        setShow(false)

                    })
            }
            else {
                console.log('checked', ckamout)
                let amountIn = web3main.utils.toBN(fromExponential((ckamout * amountToBuy) * Math.pow(10, 18)));
                console.log('amoutck', amountIn)
                let tokenaddress = '0x0000000000000000000000000000000000000000'
                console.log('checked', tokenid)
                let ownerAddress = gift ? addressToSend : userwalletaddresss
                swaping.methods.mintNFT(ownerAddress, tokenid, 0, amountToBuy, web3main.utils.fromAscii("")).send({ from: userwalletaddresss, value: amountIn })
                    .then((recipt) => {
                        // console.log(recipt);
                        setShow(false)
                        localStorage.removeItem(`buylist${tokenid}`)
                        window.location.reload(true)
                    })
                    .catch((err) => {
                        console.log(err)
                        setShow(false)

                    })

            }
        }
    }

    return (

        <>
            {
                fdata ?
                    <section className="item-details-area">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-12 col-lg-5">
                                    <div className="item-info">
                                        <div className="item-thumb coll-img text-center">
                                            <img src={`https://loopart.mypinata.cloud/ipfs/${fdata ? fdata[6] : null}`} alt="" />
                                        </div>
                                        {/* <div className="card no-hover countdown-times my-4">
                                    <div className="countdown d-flex justify-content-center" data-date={this.state.initData.date} />
                                </div> */}
                                        {
                                            buyaucprice > 0 ?

                                                <div className="card no-hover countdown-times my-4">
                                                    <div className="countdow  d-flex justify-content-center " >
                                                        <h5 style={{ fontSize: '20px', marginRight: '10px' }}><span style={{ fontWeight: 'bold' }}></span>{time?.d} Days</h5>
                                                        <h5 style={{ fontSize: '20px', marginRight: '10px' }}><span style={{ fontWeight: 'bold' }}></span>{time?.h} Hours</h5>
                                                        <h5 style={{ fontSize: '20px', marginRight: '10px' }}><span style={{ fontWeight: 'bold' }}></span>{time?.m} Minutes</h5>
                                                    </div>
                                                </div> : null
                                        }


                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    {/* Content */}
                                    <div style={{ textTransform: 'capitalize' }} className="content mt-5 mt-lg-0">
                                        <h3 style={{ fontSize: '30px', color: '#99B7FF', textTransform: 'capitalize', fontFamily: "Georgia, 'Times New Roman', Times, serif" }} className="m-0">{fdata[1]}</h3>
                                        <p style={{ fontSize: '15px', textTransform: 'capitalize', fontFamily: "Georgia, 'Times New Roman', Times, serif" }}>{fdata[5]}</p>
                                        {/* Owner */}
                                        <div className="owner d-flex align-items-center">
                                            <span>Owned By</span>
                                            <a className="owner-meta d-flex align-items-center ml-3" >
                                                <img className="avatar-sm rounded-circle" src={`https://loopart.mypinata.cloud/ipfs/${fdata ? fdata[6] : null}`} alt="" />
                                                <h6 style={{ textTransform: 'capitalize' }} className="ml-2">{fdata[3]}</h6>
                                            </a>
                                        </div>
                                        {/* Item Info List */}
                                        <div className="item-info-list mt-4">

                                        </div>
                                        <div className="row items">

                                            {buyaucprice ?
                                                <div className="col-12 item px-lg-2">
                                                    <div className="card no-hover">
                                                        <h4 className="mt-0 mb-2">Highest Bid</h4>
                                                        <div className="price d-flex justify-content-between align-items-center">
                                                            <span>{buyaucprice > auch?.val ? buyaucprice : auch?.val}</span>
                                                            <span>{buyaucprice}</span>
                                                        </div>
                                                    </div>
                                                </div> : null}

                                            <div className="col-12 item px-lg-2">
                                                <div className="card no-hover">
                                                    <h4 className="mt-0 mb-2">Price</h4>
                                                    <div className="price d-flex justify-content-between align-items-center">
                                                        <span>{cklist?.ck ? Number(cspric?.value) / 1000000000 : Number(cspric?.value) / 1000000000000000000} {cklist?.ck ? "CSDOGE" : "BNB"}</span>
                                                        <span>{cklist?.copy} of {cklist?.total}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group d-flex mt-5">
                                            <div className='ml-2'>
                                                <label for="Own" >Own</label>
                                                <input type="radio" id="Own" name="fav_language" checked={gift === false} onClick={() => setgift(false)} />
                                            </div>
                                            <div className='ml-1'>
                                                <label for="Gift">Gift</label>
                                                <input type="radio" id="Gift" name="fav_language" checked={gift === true} onClick={() => { setAmountToBuy(0); setgift(true); }} />
                                            </div>



                                        </div>
                                        {gift ?
                                            <>
                                                <label className='mt-3'>Addresses</label>
                                                {/* <input type="text" type="string" className="mt-3" value={addressToSend} onChange={(e)=>  setAddressToSend(e.target.value)}>
                                </input> */}
                                                <input type='text' placeholder="click here to enter BEP20 addresses" className='mt-3' onClick={() => setOpen(true)} />
                                            </> : null
                                        }
                                        {gift ?
                                            <input  type="number" className="mt-3" value={amountToBuy} disabled>
                                            </input>
                                            :
                                            <input  type="number" className="mt-3" value={amountToBuy} onChange={(e) => setAmountToBuy(e.target.value)}>
                                            </input>}
                                        {gift ?
                                            cklist?.ck ? <button className="btn btn-bordered-white btn-smaller mt-3 w-100"
                                                onClick={() => bulkSend(fdata[0], true)}>Bulk Send</button> :
                                                <button className="btn btn-bordered-white btn-smaller mt-3 w-100"
                                                    onClick={() => bulkSend(fdata[0], false)}>Bulk Send</button>
                                            :
                                            cklist?.ck ? <button className="btn btn-bordered-white btn-smaller mt-3 w-100"
                                                onClick={() => buycopiesnft(fdata[0], true)}>Bulk Buy</button> :
                                                <button className="btn btn-bordered-white btn-smaller mt-3 w-100"
                                                    onClick={() => buycopiesnft(fdata[0], false)}>Bulk Buy</button>
                                        }
                                        {
                                            fdata[8] ?
                                                <a href={`${sitelink}/staking/${fdata[0]}?nftMintingContract1=${nftMintingContract1}&nftStakingContract1=${stakeid} `}>
                                                    <button className="btn btn-bordered-white btn-smaller mt-3 w-100"
                                                    >Staking</button> </a> : null

                                        }
                                    </div>
                                </div>
                                <Modal
                                    show={show}
                                    onHide={handleClose}
                                    backdrop="static"
                                    keyboard={false}
                                >

                                    <Modal.Body>


                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}><Spinner animation="grow" variant="light" />
                                            <Spinner animation="grow" variant="light" />
                                            <Spinner animation="grow" variant="light" />
                                            <Spinner animation="grow" variant="light" />
                                            <Spinner animation="grow" variant="light" />
                                            <Spinner animation="grow" variant="light" />
                                        </div>
                                    </Modal.Body>
                                </Modal>

                            </div>
                        </div>
                    </section> : <div style={{ height: '80vh', display: 'flex', paddingTop: '50vh', justifyContent: 'center', alignContent: 'center' }}>
                        <Spinner animation="grow" variant="light" />
                        <Spinner animation="grow" variant="light" />
                        <Spinner animation="grow" variant="light" />
                        <Spinner animation="grow" variant="light" />
                        <Spinner animation="grow" variant="light" />

                    </div>
            }
            <DialogModal open={open} handleModalClose={handleCloseModal} setAmountToBuy={setAmountToBuy} setAddressToSend={setAddressToSend}></DialogModal>
        </>
    );
}


export default Csdetail;