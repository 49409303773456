import React, { useEffect, useState } from 'react';
import { useLocation,  useParams } from 'react-router-dom';
import nft from '../../abi/abi.json'
import stack from '../../abi/stack.json'
import erc20 from '../../abi/ERC20.json'
import {
    sitelink,
    csdogeToken
} from '../../config';

function Allstack({ acc, web3main, nftMintingContract1, nftStakingContract1 }) {
    const { id } = useParams()
    const [account, setaccount] = useState()
    useEffect(() => {
        if (acc && web3main) {
            nfttotal()
            totalSupply()
            getTotalRewards();
        }

    }, [acc, web3main])
    const [show, setShow] = useState(false);
    const [fdata, setfdata] = useState([])
    const [tottoken, settottoken] = useState()
    const [poolInfototalStaked, setPoolInfototalStaked] = useState();
    const [poolInfoendOfPool, setPoolInfoendOfPool] = useState();
    const [poolInfostartOfDeposit, setPoolInfostartOfDeposit] = useState();
    const [poolInfoTotalReward, setPoolInfoTotalReward] = useState();
    const [allocatedToken, setAllocatedToken] = useState();
    const [poolName, setPoolName] = useState();

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const getTotalRewards = async () => {
        const accounts = await web3main.eth.getAccounts();
        setaccount(accounts[0])
        let userwalletaddresss = accounts[0];
        let swaping = new web3main.eth.Contract(stack, nftStakingContract1)

        swaping.methods.getTotalRewards().call({ from: userwalletaddresss })
            .then((rewards) => {

                setPoolInfoTotalReward(parseFloat(rewards / 1e9))
            }).catch()
    }
    const nfttotal = async () => {
        ////console.log('four fun')

        if (web3main) {
            const accounts = await web3main.eth.getAccounts();
            setaccount(accounts[0])
            let userwalletaddresss = accounts[0];
            
            ////console.log(nft, nftMintingContract1, '~~~~~~~~~~~')
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)

            swaping.methods.tokenidmint().call({ from: userwalletaddresss })
                .then((length) => {
                    for (let i = 1; i <= Number(length); i++) {
                        nftstackdetails(i)
                    }
                }).catch()
            getPoolInfos1();

        }
    }
    const getPoolInfos1 = async () => {
        const accounts = await web3main.eth.getAccounts();
        setaccount(accounts[0])
        let userwalletaddresss = accounts[0];
        let swaping1 = new web3main.eth.Contract(stack, nftStakingContract1)

        await swaping1.methods.PoolInfototalStaked().call({ from: userwalletaddresss })
            .then((result) => {
                setPoolInfototalStaked(result);
            })
        await swaping1.methods.PoolInfoendOfPool().call({ from: userwalletaddresss })
            .then((result) => {
                let temp = new Date(result * 1000)
                let month = temp.getMonth() * 1;
                month = monthNames[month];
                setPoolInfoendOfPool(temp.getHours() + " : " + temp.getMinutes() + " : " + temp.getSeconds() + "<br/>" + temp.getDate() + " : " + month + ' : ' + temp.getFullYear())
            })
        await swaping1.methods.PoolInfostartOfDeposit().call({ from: userwalletaddresss })
            .then((result) => {
                let temp = new Date(result * 1000)
                let month = temp.getMonth() * 1;
                month = monthNames[month];
                setPoolInfostartOfDeposit(temp.getHours() + " : " + temp.getMinutes() + " : " + temp.getSeconds() + "<br/>" + temp.getDate() + " : " + month + ' : ' + temp.getFullYear())
            })
        await swaping1.methods.PoolInfoTotalReward().call({ from: userwalletaddresss })
            .then((result) => {

                setAllocatedToken(parseFloat(result / 1e9))
            })
        await swaping1.methods.PoolName().call({ from: userwalletaddresss })
            .then((result) => {

                setPoolName(result)
            })

        //console.log('PoolInfoendOfPool', poolInfoendOfPool, poolInfototalStaked, poolInfostartOfDeposit, poolInfoTotalReward)
    }

    const nftinfo = async (id) => {
        // ////console.log('four fun')
        if (web3main) {
            const accounts = await web3main.eth.getAccounts();
           
            let userwalletaddresss = accounts[0];
            
            let swaping = await new web3main.eth.Contract(nft, nftMintingContract1)

            swaping.methods.nftInformation(id[0]).call({ from: userwalletaddresss })
                .then((fees) => {
                    ////console.log("fees", fees)
                    getallasset({ ...id, 9: fees[6], 10: fees[1] })
                }).catch(err => {
                    ////console.log(err)
                })

        }
    }
    const getallasset = (data) => {
        setfdata(old => [
            ...old, data
        ])

    }
    const nftstack = async () => {
        setShow(true)
        if (web3main) {

            const accounts = await web3main.eth.getAccounts();
           
            let userwalletaddresss = accounts[0];
            
            let swaping = new web3main.eth.Contract(nft, nftMintingContract1)
    
            swaping.methods.nftstake(id).send({ from: userwalletaddresss })
                .then((fees) => {
                    ////console.log(fees);
                    nftlock()
                }).catch()

        }
    }
    const totalSupply = async (id) => {
        if (web3main) {
            const accounts = await web3main.eth.getAccounts();
           
            let userwalletaddresss = accounts[0];
            // window.web3 = new Web3(window.ethereum);
            let swaping = new web3main.eth.Contract(erc20, csdogeToken)

            swaping.methods.totalSupply().call({ from: userwalletaddresss })
                .then((fees) => {
                    ////console.log("cvcv", fees);
                    settottoken(fees)
                }).catch()
        }
    }

    const nftlock = async () => {
        if (web3main) {
            const accounts = await web3main.eth.getAccounts();
           
            let userwalletaddresss = accounts[0];
            
            let swaping = new web3main.eth.Contract(stack, nftStakingContract1)
            swaping.methods.stakenft(id).send({ from: userwalletaddresss })
                .then((fees) => {
                    ////console.log(fees);
                    setShow(false)
                }).catch()
        }
    }

    
    const nftstackdetails = async (id) => {
        if (web3main) {
            const accounts = await web3main.eth.getAccounts();
           
            let userwalletaddresss = accounts[0];
            let swaping = new web3main.eth.Contract(stack, nftStakingContract1)

            swaping.methods.nftStakeDetails(id).call({ from: userwalletaddresss })
                .then((fees) => {
                    rewardsearnnft(fees)
                }).catch()

        }
    }

    const rewardsearnnft = async (id) => {
        if (web3main) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let userwalletaddresss = accounts[0];

            let swaping = new web3main.eth.Contract(stack, nftStakingContract1)
            swaping.methods.calculateRewardsPerToken(userwalletaddresss, id[0]).call({ from: userwalletaddresss })
                .then((fees) => {

                    nftinfo({ ...id, 8: fees })
                }).catch(err => {
                })

        }
    }

    return (
        <div className='main'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4 col-12 mt-3 d-flex justify-content-center align-items-center'>
                        <div className='card d-flex justify-content-between px-3 py-6 w-100'>
                            <h3 style={{ color: 'lightyellow', fontStyle: 'bold', textAlign: 'center', textTransform: 'uppercase' }}>{poolName}</h3>
                        </div>
                    </div>
                    <div className='col-md-8 col-12 mt-3'>
                        <div className='card '>
                            <div className='d-flex flex-row justify-content-between px-3'>
                                <div className=''>
                                    <h5 style={{ fontSize: '15px', fontWeight: 'bold' }}>NFT Staked</h5>
                                    <h5 className='d-flex justify-content-center align-items-center text-center' style={{ fontSize: '15px', fontWeight: 'bold', color: 'rgb(153, 183, 255)' }}>{poolInfototalStaked}</h5>
                                </div>
                                <div>
                                    <h5 style={{ fontSize: '15px', fontWeight: 'bold' }}>Pool Start Date</h5>
                                    <div className='d-flex justify-content-center align-items-center text-center' style={{ color: 'rgb(153, 183, 255)', fontSize: '15px' }} dangerouslySetInnerHTML={{ __html: poolInfostartOfDeposit }}></div>

                                </div>
                                <div className=''>
                                    <h5 style={{ fontSize: '15px', fontWeight: 'bold' }}>Pool End Date</h5>
                                    <div className='d-flex justify-content-center align-items-center text-center' style={{ color: 'rgb(153, 183, 255)', fontSize: '15px' }} dangerouslySetInnerHTML={{ __html: poolInfoendOfPool }}></div>
                                </div>
                                <div className=''>
                                    <h5 style={{ fontSize: '15px', fontWeight: 'bold' }}>Total Rewards</h5>
                                    <h5 className='d-flex justify-content-center align-items-center text-center' style={{ fontSize: '15px', fontWeight: 'bold', color: 'rgb(153, 183, 255)' }}>{poolInfoTotalReward}</h5>
                                </div>
                                <div>
                                    <h5 style={{ fontSize: '15px', fontWeight: 'bold' }}>Allocated Token</h5>
                                    <h5 className='d-flex justify-content-center align-items-center text-center' style={{ fontSize: '15px', fontWeight: 'bold', color: 'rgb(153, 183, 255)' }}>{allocatedToken}</h5>
                                </div>
                            </div>
                            <div className='d-flex flex-row px-3 pt-2'>
                                <div className=''>
                                    <h5 style={{ fontSize: '15px', fontWeight: 'bold' }}>Pool Infos:</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    {
                        fdata?.map((item, index) => (
                            <div className='col-md-6 col-xl-3 col-12 mb-3' key={index}>
                                <div className='card shadow h-560 position-relative'>
                                    <div className='img p-3'>
                                        <a href={`/staking/${item[0]}?nftMintingContract1=` + nftMintingContract1 + '&nftStakingContract1=' + nftStakingContract1}>
                                            <img src={`https://loopart.mypinata.cloud/ipfs/${item ? item[9] : null}`} className='img-fluid' />
                                        </a>

                                    </div>
                                    <div className='cardbody position-absolute b-20'>
                                        <h3 className='text-center'>{item ? item[10] : null}</h3>
                                        <div className='d-flex justify-content-between px-3'>
                                            <h5 style={{ fontSize: '20px' }}>Id:</h5>
                                            <h5 style={{ fontSize: '20px', fontWeight: 'bold' }}>{item[0]}</h5>
                                        </div>
                                        <div className='d-flex justify-content-between px-3'>
                                            <h5 style={{ fontSize: '20px' }}>Category:</h5>
                                            <h5 style={{ fontSize: '20px', fontWeight: 'bold' }}>NFT</h5>
                                        </div>
                                        < div className='onbtn'>
                                            <a href={`${sitelink}/item-details/${item[0]}`}>
                                                <button>View NFTs on Nftartland</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>


            </div>
        </div>
    )
}

export default Allstack
