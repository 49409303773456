import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap'
import Csdetails from "../themes/Cs-detail";
import Csdogemain from "../themes/csdogemain";
import Createcsdoge from "../themes/createcs";
import ScrollToTop from "../ScrollToTop";
import Header from "../components/Header/Header";
import Allstack from "../components/Allstack";
import Mystack from "../components/Mystack";
import Staking from "../components/Staking";
import StackHeader from "../components/StackHeader";
import MintHeader from "../components/MintHeader";
import {
  netCapitalizeString,
  chainIdHex,
  chainIdString,
  nftMintingContract1,
  nftStakingContract1,
  nftStakingContract2,
  nftMintingContract2
} from '../config';

function MyRouts() {
  const [accountid, setaccountid] = useState()
  const [accountid1, setaccountid1] = useState()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [chainid, setchainid] = useState()
  const [acc, setacc] = useState(false)
  const [web3main, setweb3main] = useState()
  const [prov, setprov] = useState()
  const change = ((v) => {

    setacc(v)
  })
  const provider1 = ((v) => {
    setprov(v)
  })
  const web3m = ((v) => {
    setweb3main(v)

  })
  useEffect(() => {
    if (accountid != accountid1) {
      window.location.reload()
      // history.push('/')
    }

  }, [accountid])
  useEffect(async () => {
    if (acc && prov) {

      const chainId = await prov.request({ method: 'eth_chainId' });
      console.log('chain', chainid)
      setchainid(chainId)
      const accountsa = await prov.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIdString }], // chainId must be in hexadecimal numbers
      });
      await prov.on('chainChanged', (chainId) => {
        window.location.reload();
      });
    }


  }, [acc, prov]);
  useEffect(() => {
    if (chainid && acc) {
      console.log('ckli', chainid)
      chainid == chainIdHex ? setShow(false) : setShow(true)
    }

  }, [accountid1, chainid, acc])
  useEffect(async () => {
    if (acc && web3main) {
      if (window.ethereum) {
        async function getAccount() {
          const accounts = await window.ethereum.enable();
        }
        window.ethereum.on('accountsChanged', function (accounts) {
          console.log("account", accounts)
          getAccount();
        })
      }
    }
  }, [])


  return (
    <div>
      <Router>
        <ScrollToTop />
        <Header change={change} web3m={web3m} provider1={provider1} />
        <Switch>
          <Route exact path="/cs-details/:csid/:address/:stakeid" >
            <Csdetails acc={acc} web3main={web3main} prov={prov} />
          </Route>
          <Route exact path="/" >
            <MintHeader/>
            <Csdogemain acc={acc} web3main={web3main} prov={prov} />
          </Route>
          <Route exact path="/createcsdoge/:id" >
            <Createcsdoge acc={acc} web3main={web3main} prov={prov} />
          </Route>
          <Route exact path="/staking">
              <div style={{marginTop:'130px',  backgroundColor:"#050023"}}>
                  <StackHeader/>
                <Allstack acc={acc} web3main={web3main} prov={prov} nftMintingContract1={nftMintingContract1} nftStakingContract1={nftStakingContract1} />
                <Allstack acc={acc} web3main={web3main} prov={prov} nftMintingContract1={nftMintingContract2} nftStakingContract1={nftStakingContract2} />
              </div>
          </Route>
          <Route exact path="/mystack" >
              <div style={{marginTop:'130px', backgroundColor:"#050023"}}>
                    <StackHeader path={"mystack"}/>
                <Mystack acc={acc} web3main={web3main} prov={prov} nftMintingContract1={nftMintingContract1} nftStakingContract1={nftStakingContract1} />
                <Mystack acc={acc} web3main={web3main} prov={prov} nftMintingContract1={nftMintingContract2} nftStakingContract1={nftStakingContract2} />
              </div>
          </Route>
          <Route path="/staking/:id" >
              <Staking acc={acc} web3main={web3main} prov={prov} />
          </Route>
        </Switch>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >

          <Modal.Body>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <h5>Switch Wallet to BSC {netCapitalizeString}</h5>

            </div>

          </Modal.Body>
        </Modal>
      </Router>
    </div>
  );
}

export default MyRouts;