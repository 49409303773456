import React from 'react';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import Csdetail from '../components/ItemDetails/Csdetail';

function Coldetails({ acc, web3main, prov }) {

    return (
        <div className="main mt-5">
            <Csdetail acc={acc} web3main={web3main} prov={prov} />
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </div>
    );
}


export default Coldetails;